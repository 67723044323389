body {
  margin: 0;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ================================================================================= */
/* Bootstrap Overrides */
/* ================================================================================= */

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
}

#mainNav {
  font-family: 'Nunito Sans', sans-serif;
}

a {
  color: #ffc107;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-thickness: 1px;
}
a:hover {
  color: #ffc107;
  opacity: 0.81;
}

.lead {
  text-align: justify;
}

#mainNav .navbar-nav li.nav-item a.nav-link:focus,
#mainNav .navbar-nav li.nav-item a.nav-link:hover {
  color: #ffc107;
}

.nav-tabs .nav-link {
  border: 0.0625rem solid transparent;
}

.navbar-brand {
  /* kill these */
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.25rem;
}
.navbar-brand img {
  max-height: 1.5rem;
}

.divider-custom .divider-custom-line {
  height: 0.15rem;
}

#mainNav {
  font-weight: 500;
}
#mainNav .navbar-brand img {
  max-height: 1.75rem;
}

.copyright {
  font-size: 1.125rem;
}

.copyright img {
  max-height: 1rem;
  margin: -0.25rem 0 0 0.75rem;
}

/* accordion overrides */
.accordion-button {
  background-color: #2c3e50;
  color: #f7f7f7;
  font-size: 1.25rem;
  font-style: italic;
  margin: 0 auto;
  text-align: center;
  width: 125px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f7f7f7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f7f7f7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
  color: #f7f7f7;
  background-color: #2c3e50;
  box-shadow: none;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #2c3e50;
  outline: 0;
  box-shadow: none;
}
.accordion-item {
  background-color: #2c3e50;
  border: 0;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
/* end: accordion overrides */

/* ================================================================================= */
/* Site Styling */
/* ================================================================================= */

.footer {
  padding-top: 0;
}

.show-date {
  font-size: 1.25rem;
  font-weight: 300;
  margin: 1rem 0 0 0;
}

.show-accordion {
  margin: 0 auto;
}

.show-accordion-link {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-thickness: 1px;
  font-weight: 600;
}
.show-accordion-link:hover {
  opacity: 0.81;
}
